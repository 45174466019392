import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import ExternalLink from "../../../components/externallink"
import Screenshot from "../../../components/screenshot"
import SubText from "../../../components/subtext"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"

import { useTheme } from "../../../services/theme"

const WebflowCodeSnippetFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I add Vimkit to a Webflow site?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    title="How do I add Vimkit to a Webflow site?"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="960px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Add a website on Vimkit.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Navigate to the websites page and click 'Add Websites'.
                        <SubText align="left">Login to your Vimkit account if you have not!</SubText>
                        <br/>
                        &#8594;Enter your website's domain and click 'Add Website'. 
                        <SubText align="left">Don't include folders or full page paths.
                            Just the domain (or subdomain) is enough.
                            Choose the stage if you have multiple environments for testing or development.
                            If you have only one environment, just choose 'Production'.</SubText>
                    </SegmentText>
                    <Screenshot 
                        fluid={data.addWebsiteScreenshot.childImageSharp.fluid} 
                        minWidth="340px" 
                        maxWidth="960px"
                        marginBottom="40px"
                        title="Add a website"
                        alt="Add website screenshot"
                    />
                    <SegmentSubTitle id="installcode">Step 2: Copy and paste the Vimkit code snippet on your Webflow site.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Copy the code snippet. 
                        <br/><br/>
                        &#8594;Paste the code snippet in the Head Code section of your Webflow site (see 
                        <ExternalLink 
                            display="inline"
                            to="https://university.webflow.com/article/how-to-add-custom-head-and-body-code-to-a-webflow-site#custom-code-in-your-project-settings">
                                &nbsp;Webflow's documentation
                        </ExternalLink> for details).
                    </SegmentText>
                    <Screenshot fluid={data.installCodeScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="40px"/>
                    <SegmentSubTitle>Step 3: Publish your Webflow site and check if the code snippet was installed correctly.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Click publish on your Webflow site (see 
                        <ExternalLink 
                            display="inline" 
                            to="https://university.webflow.com/article/publish-your-project#publish-the-whole-project"
                        >
                            &nbsp;Webflow's documentation
                        </ExternalLink> for details). 
                        <SubText align="left">If you host your site somewhere else, export the code from Webflow
                            and follow your standard process to publish your website.</SubText><br/>
                            &#8594;Once your website has been published, click 'Verify' on Vimkit.
                            You'll see a confirmation if the code snippet has been installed correctly.
                    </SegmentText>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
        </SiteLayout>    
    )
}

export default WebflowCodeSnippetFaq

export const query = graphql`
    query {
        addWebsiteScreenshot: file(relativePath: { eq: "screenshots/addwebsite.png" }) {
            childImageSharp {
                fluid(maxWidth: 960, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        installCodeScreenshot: file(relativePath: { eq: "screenshots/installcode.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`